<template>
  <div class="addBuild wrapper">
    
      
      <NewBuild v-if="role === 'ministry'"  :geo="objInfo.geolocation" :cadastral_number="objInfo.cadastral_number" />
      <ExistBuild  :cadastral_number="objInfo.cadastral_number" v-else />

    
  </div>
</template>

<script type="text/javascript">
import NewBuild from "@/components/NewBuild.vue";
import ExistBuild from "@/components/ExistBuild.vue";

export default {
  components: {
    NewBuild,
    ExistBuild,
  },
  data() {
    return {
      tabCount: 3,
      currentTab: 0,
      maxTab: 7,
      radio: "1",
      objInfo: {},
    };
  },
  computed: {
    isCreated() {
      return !!this.$route.query.id;
    },
    role() {
      return this.$store.state.role;
    },
  },
  mounted() {
    if(this.$route.params.id){
      axios
      .get(`/building/generalinformation/${this.$route.params.id}/`)
      .then((response) => {
        this.objInfo = response.data;
      });
    }
    
  },
};
</script>

<style type="text/css" lang="scss">
.el-collapse-item__header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  color: #004787 !important;
  padding: 15px 0;
  border-bottom: none;
}
 
.prim .el-radio__input.is-checked .el-radio__inner {
  border-color: #314e52 !important;
  background-color: #314e52 !important;
}
.el-radio.is-bordered.is-checked {
  border-color: #314e52;
}
.el-radio {
  margin-right: 10px;
}
</style>

<style type="text/css" lang="scss" scoped="">
.del-tab {
  position: absolute;
  right: -3px;
  top: 0px;
  font-size: 11px;
  opacity: 0;
  padding: 2px;
  border-radius: 50%;
  &:hover {
    background-color: #004787;
    color: #fff;
    border-radius: 50%;
  }
}
.build-tab li.addTab {
  display: flex;
  width: auto !important;
  user-select: none;
  padding: 0 10px;
  background: #004787;
  color: #fff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: 10px;
}
.build-tab {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #d4d4d4;
    font-size: 17px;
    line-height: 24px;
    color: #004787;
    position: relative;
    user-select: none;
    &:hover {
      .del-tab {
        opacity: 1;
      }
    }
    &.active {
      border-bottom: solid 3px #004787;
    }
  }
}
</style>